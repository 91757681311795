<template>
    <modal ref="modalAddFolder" :titulo="titulo" :adicional="`${this.model.id != null ? 'Guardar' : 'Crear'}`" @adicional="add">
        <ValidationObserver ref="folderValidator">
            <div class="row m-3 f-15 justify-content-center">
                <div class="col-10">
                    <p class="input-label-top">Nombre de la carpeta</p>
                    <ValidationProvider v-slot="{errors}" name="nombre" rules="required">
                        <el-input v-model="model.name" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            titulo: 'Crear carpeta',
            model: {
                id: null,
                name: ''
            }
        }
    },
    methods: {
        toggle(data){
            this.$refs.folderValidator.reset() 
            if (data) {
                this.model.id = data.id
                this.model.name = data.nombre
                this.titulo = "Editar carpeta"
            } else {
                this.model = {
                    id: null,
                    name: ''
                }
            }
            this.$refs.modalAddFolder.toggle()
        },
        async add(){
            const valid = await this.$refs.folderValidator.validate() 
            if (valid) {
                if (this.model.id != null) {
                    this.$emit('update', this.model)
                } else {
                    this.$emit('add', this.model.name)
                }
                this.$refs.modalAddFolder.toggle()
            }
            this.model = {
                id: null,
                name: ''
            
            }
        },
    }
}
</script>